<template>
  <div class="pt-6 pb-10">
    <div v-if="review" class="grid grid-cols-3 gap-6 items-start">
      <div class="col-span-3">
        <MessageBanner v-if="message" :headline="message.headline" :message="message.text" :type="message.type">
          <button v-if="canSendReminder" class="btn-primary-white-s" :disabled="reminderDisabled" @click="$emit('send-reminder')">
            {{ t('Reviews.product.view.actions.sendReminder') }}
            <MdiSvg :path="mdiBell" size="16" class="ml-1" />
          </button>
        </MessageBanner>
      </div>
      <ReviewOverview class="col-span-1" :review="review" />
      <div class="col-span-2">
        <VideoReview v-if="review.video" class="col-span-2" :video-review="review.video" />
        <TextReviewQuotes v-else class="mb-10" :review="review" />
        <ReplyItem
          v-if="!!reviewReply"
          :product-title="product.title"
          :review-reply="reviewReply"
          @delete-reply="$emit('delete-reply')"
          @edit-reply="$emit('edit-reply')"
        />
        <RemindersHistory
          v-if="surveyResponse.reminders.length > 0"
          :author-name="surveyResponse.reviewerName || undefined"
          class="my-6"
          :reminders="surveyResponse.reminders"
        />
      </div>
    </div>
  </div>
  <WorkInProgressHint context="details" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { mdiBell } from '@mdi/js'
import { MdiSvg, MessageBanner } from '@ramp106/omrjs-core-ui'
import { ReviewOverview, TextReviewQuotes, VideoReview } from '@ramp106/omrjs-reviews-ui'
import { type ProductById, SurveyResponseStateEnum } from '@/gql/reviews'
import ReplyItem from './ReplyItem.vue'
import RemindersHistory from './RemindersHistory.vue'
import type { SurveyResponse } from './types'
import WorkInProgressHint from '@/components/ReviewsManagement/WorkInProgressHint.vue'

const props = defineProps<{
  product: Pick<ProductById, 'id' | 'title'>
  surveyResponse: SurveyResponse
  reminderDisabled?: boolean
}>()

defineEmits(['send-reminder', 'delete-reply', 'edit-reply'])

const { t } = useI18n()

const review = computed(() => {
  const response = props.surveyResponse

  return {
    positive: response.positiveAnswer || response.review?.positive,
    negative: response.negativeAnswer || response.review?.negative,
    problems: response.problemsSolved || response.review?.problems,
    anonymous: response.anonymous,
    companyField: response.reviewerIndustry,
    companyName: response.reviewerCompany,
    companyPosition: response.reviewerPosition || response.review?.companyPosition,
    companySize: response.reviewerCompanySize,
    id: response.id,
    incentive: !!response.incentive,
    publishedAt: response.publishedAt || response.firstPublishedAt || response.createdAt,
    reviewerFirstname: response.review?.reviewerFirstname,
    reviewerName: response.reviewerName,
    recommendationScore: response.recommendationScore || response.review?.recommendationScore,
    title: response.title,
    product: { title: response.review?.product?.title || props.product.title },
    video: response.review?.video,
  }
})

const reviewReply = computed(() => props.surveyResponse.review?.reviewReply || null)

const message = computed(() => {
  switch (props.surveyResponse.aasmState) {
    case SurveyResponseStateEnum.Rejected:
      return {
        headline: t('Reviews.product.review.rejected.headline'),
        text: t(`Reviews.product.review.rejected.${props.surveyResponse.rejectedReason}`),
        type: 'error' as const,
      }
    case SurveyResponseStateEnum.Drafted:
    case SurveyResponseStateEnum.Stale:
      return {
        headline: t('Reviews.product.review.draftedOrStale.headline'),
        text: t('Reviews.product.review.draftedOrStale.message', { authorName: review.value.reviewerName }),
        type: 'info' as const,
      }
    default:
      return null
  }
})
const canSendReminder = computed(
  () =>
    !props.reminderDisabled &&
    [SurveyResponseStateEnum.Drafted, SurveyResponseStateEnum.Stale].includes(props.surveyResponse.aasmState as SurveyResponseStateEnum),
)
</script>
